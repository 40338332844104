import { Controller } from "@hotwired/stimulus";
import { Toast } from "../src";

export default class extends Controller {
  connect() {
    this.element.addEventListener("ajax:complete", this.handleAjaxSuccess);
  }

  handleAjaxSuccess = (event) => {
    const [xhr, _status] = event.detail;
    const { status, response } = xhr;
    const json = JSON.parse(response);
    const { message } = json;
    const icon = [200, 201, 204].includes(status) ? "success" : "error";
    const text = message || "Dados salvos com sucesso";

    Toast.fire({ icon, text });
  };
}
