import CustomValidator from "./CustomValidator";
import { validateBr } from "js-brasil";

export default class PersonalOrCompanyDocumentValidator extends CustomValidator {
  hasError() {
    return !validateBr.cpfcnpj(this._targetField.value);
  }

  get defaultErrorMessage() {
    return `não é um CPF/CNPJ válido`;
  }
}
