import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ['master'];

	connect() {
		this.masterTargets.forEach((element) => this.applyRules(element));
	}

	change = (event) => {
		this.applyRules(event.target);
	};

	applyRules = (masterElement) => {
		const dependencies = this.findAssociatedFields(masterElement.name);

		if (masterElement.type == 'radio') {
			dependencies.forEach((formElement) => this.fromRadioButtons(masterElement, formElement));
		} else if (masterElement.type == 'checkbox') {
			dependencies.forEach((formElement) => this.fromCheckbox(masterElement, formElement));
		} else {
			dependencies.forEach((formElement) => this.fromInputOrSelect(masterElement, formElement));
		}

		this.notifyFormChanges();
	};

	findAssociatedFields = (name) => this.element.querySelectorAll(`[data-enabled-by="${name}"]`);

	fromInputOrSelect = (masterElement, associatedElement) => {
		const expectedValue = associatedElement.dataset['expectedValue'];

		if (
			(expectedValue == undefined && masterElement.value != '') ||
			masterElement.value == expectedValue
		) {
			this.enableField(associatedElement);
		} else {
			this.disableField(associatedElement);
		}

		this.notifyFieldChange(associatedElement);
	};

	fromCheckbox = (masterElement, associatedElement) => {
		const expectedValue = associatedElement.dataset['expectedValue'];
		const enableField = expectedValue == 'checked' && masterElement.checked;

		if (enableField) {
			this.enableField(associatedElement);
		} else {
			this.disableField(associatedElement);
		}

		this.notifyFieldChange(associatedElement);
	};

	fromRadioButtons = (masterElement, associatedElement) => {
		const expectedValue = associatedElement.dataset['expectedValue'];
		const value = document.querySelector(`[name='${masterElement.name}']:checked`).value;

		if (value == expectedValue) {
			this.enableField(associatedElement);
		} else {
			this.disableField(associatedElement);
		}

		this.notifyFieldChange(associatedElement);
	};

	enableField = (element) => element.removeAttribute('disabled');

	disableField = (element) => {
		element.setAttribute('disabled', '');
		element.value = '';

		// let the element know that it has been changed
		const event = new Event('change', { bubbles: true });
		element.dispatchEvent(event);
	};

	notifyFormChanges = () => {
		this.element.dispatchEvent(new Event('form:fieldsChanged'));
	};

	notifyFieldChange = (element) => {
		element.dispatchEvent(new Event('field:changed'));
	};
}
