import { Controller } from "@hotwired/stimulus";
import { Toast } from "../../javascript/src";

export default class extends Controller {
  static targets = ["issuesContainer", "noRecordMessage", "form"];

  connect() {
    this.handleIssuesContainerVisibility();

    this.issuesContainerTarget.addEventListener(
      "DOMSubtreeModified",
      (_event) => this.handleIssuesContainerVisibility(),
      false
    );

    if (this.hasFormTarget) {
      this.formTarget.addEventListener(
        "ajax:success",
        this.handleIssueCreateSuccess
      );
      this.formTarget.addEventListener(
        "ajax:error",
        this.handleIssueCreateError
      );
    }
  }

  get countIssues() {
    return this.issuesContainerTarget.children.length;
  }

  handleIssuesContainerVisibility = () => {
    if (this.countIssues > 0) {
      this.noRecordMessageTarget.style.display = "none";
      this.issuesContainerTarget.style.display = "block";
    } else {
      this.noRecordMessageTarget.style.display = "block";
      this.issuesContainerTarget.style.display = "none";
    }
  };

  cleanForm = () => this.formTarget.reset();

  renderIssue = (issue) => {
    const issueEndpoint = `/risk_analyses/${issue.risk_analysis_id}/issues/${issue.id}`;
    const template = `
      <li class="list-group-item" data-controller="risk-analyses--issue-component" data-issue-id="${
        issue.id
      }">
        <div class="row">
          <div class="col-auto d-flex align-items-center pr-0">
            <form class="simple_form edit_issue" action="${issueEndpoint}">
              <fieldset class="form-group boolean optional issue_solved">
                <div class="custom-control custom-switch">
                  <input name="issue[${
                    issue.id
                  }][solved]" type="hidden" value="${issue.solved ? 1 : 0}">
                  <input class="custom-control-input boolean optional" id="issue_solved_${
                    issue.id
                  }" data-action="input->risk-analyses--issue-component#toggle" type="checkbox" value="1" name="issue[${
      issue.id
    }][solved]">
                  <label class="custom-control-label boolean optional" for="issue_solved_${
                    issue.id
                  }"><span class="sr-only"></span></label>
                </div>
              </fieldset>
            </form>
          </div>

          <div class="col">
            <div>${issue.reason}</div>
            <div>${issue.body || ""}</div>

            <span class="badge badge-red" data-risk-analyses--issue-component-target="statusBadge">Pendente</span>
          </div>

          <div class="col-auto d-flex align-items-center">
            <a class="btn btn-transparent-dark btn-icon" href="${issueEndpoint}" data-risk-analyses--issue-component-target="deleteButton" data-remote="true" data-method="delete" data-toggle="tooltip" data-placement="top" data-text="Remover pendência" data-original-text="" text=""><i class="fas fa-trash"></i></a>
          </div>
        </div>
      </li>
    `;

    // add new issue to the end of issues' list
    this.issuesContainerTarget.insertAdjacentHTML("beforeend", template);
  };

  handleIssueCreateSuccess = (event) => {
    const [data, _status, _xhr] = event.detail;

    Toast.fire({
      text: "Pendência adicionada com sucesso.",
      icon: "success",
    });

    this.cleanForm();

    this.renderIssue(data);
  };

  handleIssueCreateError = (data) => {
    const { message } = data;
    const text = message || "Não foi possível concluir a operação.";
    Toast.fire({ text, icon: "error" });
  };
}
