import {
	CellphoneValidator,
	CompanyDocumentValidator,
	CompareFieldsValidator,
	PersonalDocumentValidator,
	PersonalOrCompanyDocumentValidator,
} from './validators';

const defaultMessages = {
	badInput: 'não é válido',
	customError: 'não é válido',
	patternMismatch: 'não é válido',
	rangeOverflow: 'é maior que o valor permitido',
	rangeUnderflow: ' menor que o valor permitido',
	stepMismatch: 'não é válido',
	tooLong: 'é muito longo',
	tooShort: 'é muito curto',
	typeMismatch: 'não é válido',
	valueMissing: 'é obrigatório',
	compareFields: 'não é igual',
	licenseNumber: 'não é válido',
};

const customValidators = {
	cellphone: CellphoneValidator,
	companyDocument: CompanyDocumentValidator,
	compareFields: CompareFieldsValidator,
	personalDocument: PersonalDocumentValidator,
	personalOrCompanyDocument: PersonalOrCompanyDocumentValidator,
};

export default class FormValidator {
	constructor(form) {
		this.form = form;
		this.fields = [];
		this.fieldsTouched = new Set();

		this.initialize();
	}

	initialize() {
		this.setupFields();
		this.parseCustomValidators();
		this.initializeCustomValidators();
	}

	handleFormChanges = () => {
		this.initialize();

		this.fields.forEach((field) => {
			if (field.element.disabled) {
				this.removeFieldError(field.element);
			} else {
				this.fieldsTouched.has(field.element.name) && this.validateField(field);
			}
		});
	};

	findAllFieldsWithValidationSupport = () => {
		return Array.from(this.form.querySelectorAll('input:not([type=radio]), select, textarea'));
	};

	parseCustomValidators() {
		this.fields.forEach((field) => {
			const dataProperties = { ...field.element.dataset };

			Object.keys(dataProperties)
				.filter((key) => key.endsWith('Validator'))
				.forEach((key) => {
					const validatorName = key.replace('Validator', '');
					const options = dataProperties[key];

					try {
						field.constraints.push(new customValidators[validatorName](field.element, options));
					} catch (error) {
						console.warn(`${key} validator not found`, error);
					}
				});
		});
	}

	initializeCustomValidators = () => {
		this.fields.forEach((field) => {
			field.constraints.forEach((validator) => {
				validator.initialize();
			});
		});
	};

	findFieldByElementName = (name) => this.fields.find((field) => field.element.name === name);

	handleFieldChange = ({ target }) => {
		const field = this.findFieldByElementName(target.name);

		this.fieldsTouched.add(target.name);

		this.validateField(field);
	};

	validateField = (field) => {
		this.removeFieldError(field.element);

		if (!this.isFieldValid(field)) {
			this.addFieldError(field.element, this.fieldError(field.element));
		}
	};

	setupFields() {
		this.fields.forEach((field) => {
			// removing listener
			field.element.removeEventListener('input', this.handleFieldChange);
			field.element.removeEventListener('select', this.handleFieldChange);
			field.element.removeEventListener('field:validate', this.handleFieldChange);
		});

		this.fields = this.findAllFieldsWithValidationSupport().map((element) => ({
			element,
			constraints: [],
		}));

		this.fields.forEach((field) => {
			//listen to changes
			field.element.addEventListener('input', this.handleFieldChange);
			field.element.addEventListener('select', this.handleFieldChange);
			field.element.addEventListener('field:validate', this.handleFieldChange);
		});
	}

	validateForm() {
		return this.form.checkValidity();
	}

	isFieldValid(field) {
		field.element.setCustomValidity('');

		if (field.element.disabled) return true;

		if (field.constraints.length > 0) {
			for (let index in field.constraints) {
				if (field.constraints[index].hasError()) {
					field.element.setCustomValidity(field.constraints[index].errorMessage);
					break;
				}
			}
		}

		return field.element.checkValidity();
	}

	findErrorWrapper = (element) => element.parentElement.querySelector('.invalid-feedback');

	addFieldError(element, error) {
		const errorWrapper = this.findErrorWrapper(element);

		if (errorWrapper) {
			errorWrapper.innerText = error;
		}

		element.classList.add('is-invalid');
	}

	removeFieldError(element) {
		element.classList.remove('is-invalid');

		const errorWrapper = this.findErrorWrapper(element);

		if (errorWrapper) {
			errorWrapper.innerText = '';
		}
	}

	fieldError(element) {
		for (const key in element.validity) {
			if (key != 'valid' && key != 'customError' && element.validity[key]) {
				return this.translateError(element, defaultMessages[key], `data-${key}`);
			}
		}

		if (element.validity['customError']) return element.validationMessage;

		return '';
	}

	translateError(element, defaultMessage, customMessageAttribute) {
		const customMessage = element.getAttribute(customMessageAttribute);
		return customMessage ? customMessage : defaultMessage;
	}
}
