import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default class extends Controller {

  static targets = ["selector"]

  connect() {
    const { skipConfirmation  } = this.element.dataset;
    this.skipConfirmation = skipConfirmation === "true";

    if (this.hasSelectorTarget) {
      this.currentValue = this.selectorTarget.value;
    }
  }

  confirm = async (event) => {
    const { target }= event;

    // skip if value is null (no criminal report)
    if (this.skipConfirmation || !target.value) return;

    event.preventDefault();
    event.stopPropagation();

    const alertOptions = {
      title: "Solicitar relatório via email (agente externo)?",
      text: "Você pode acompanhar o status da solicitação em \"Integrações\".",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, continuar!",
      cancelButtonText: "Não, cancelar alteração.",
    };

    const { isConfirmed } = await Swal.fire(alertOptions);

    if (isConfirmed) {
      this.currentValue = target.value;
      this.element.dispatchEvent(new Event("change_confirmed"))
    } else {
      target.value = this.currentValue;
    }
  }
}
