import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default class extends Controller {

  static targets = [ "field" ]

  connect() {
    this.messageWrapper = this.element.querySelector("small");
  }

  enable = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.openAlertModal();
  }

  enableField = () => this.fieldTarget.removeAttribute("disabled");

  removeButton = () => {
    this.messageWrapper.innerHTML = "";
  }

  openAlertModal = () => {
    Swal.fire({
      title: "Você tem certeza?",
      text:
        "Ao alterar, você poderá perder dados de algumas carretas dependendo do tipo selecionado. Deseja continuar?",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: "Sim, habilitar campo.",
      cancelButtonText: "Não",
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        this.enableField();
        this.removeButton();
      }
    });
  }
}
