import Rails from "@rails/ujs";

const reducer = (accumulator, currentValue) => ({
  ...accumulator,
  ...currentValue,
});

export default class Zipcode {
  constructor(target) {
    this.target = target;
    this.form = this.target.closest("form");
    this.addressFields = this.searchForAddressFields();
    this.addListener();
  }

  addListener = () => {
    this.target.addEventListener("keyup", this.handleTargetChanges);
  };

  handleTargetChanges = (e) => {
    const q = e.target.value;

    if (this.isZipcode(q)) {
      this.search(q);
    }
  };

  isZipcode = (value) => /\d{5}-?\d{3}/.test(value);

  search = (q) => {
    Rails.ajax({
      url: `/zipcode?q=${q}`,
      type: "get",
      dataType: "json",
      success: (data) => {
        if (data) {
          this.fillAddressForm(data);
          this.addressFields["streetNumber"].focus();
          this.notifyChanges();
        }
      },
    });
  };

  searchForAddressFields = () => {
    return ["street", "streetNumber", "district", "city", "state"]
      .map((key) => {
        return { [key]: document.querySelector(this.target.dataset[key]) };
      })
      .reduce(reducer, {});
  };

  fillAddressForm = (zipcode) => {
    Object.keys(this.addressFields).forEach((fieldName) => {
      const input = this.addressFields[fieldName];
      const value = zipcode[fieldName];

      if (input && value) {
        input.value = value;
      }
    });
  };

  notifyChanges = () => {
    this.form.dispatchEvent(new Event("form:fieldsChanged"));
  };
}
