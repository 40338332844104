import { Controller } from "@hotwired/stimulus";
import { Toast } from "../src";

export default class extends Controller {
  static targets = ["toast"];

  connect() {
    if (this.hasToastTarget) {
      const toastOptions = this.parseToastSettings();
      this.showToast(toastOptions);
    }
  }

  parseToastSettings = () => {
    const element = this.toastTargets[0];
    const dataset = element.dataset;
    const { toastSeverity, toastTimer } = dataset;
    let options = {
      text: element.innerHTML,
    };

    if (toastSeverity) {
      options["icon"] = toastSeverity;
    }

    if (toastTimer) {
      options["timer"] = toastTimer;
    }

    return options;
  };

  showToast = (options) => {
    Toast.fire(options);
  };
}
