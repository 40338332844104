import { Controller } from '@hotwired/stimulus';

import 'select2';
import 'select2/dist/js/i18n/pt-BR';
import IMask from 'imask';

const PHONE_FORMATTER = IMask.createMask({
	mask: [
		{
			mask: '(00) 0000-0000',
		},
		{
			mask: '(00) 00000-0000',
		},
	],
});

const PHONE_REGEX = /^\(\d{2}\)\s\d{4,5}\-\d{4}$/;

const SELECT2_DEFAULT_OPTIONS = {
	theme: 'bootstrap4',
	language: 'pt-BR',
	width: 'style',
};

export default class extends Controller {
	static targets = ['field'];

	connect() {
		this.setup();
	}

	setup = () => {
		this.fieldTargets.forEach((element) => {
			$(element)
				.select2(this.options())
				.on('select2:select', this.dispatchChangeEvent)
				.on('select2:unselect', this.dispatchChangeEvent)
				.on('select2:clear', this.dispatchChangeEvent);
		});
	};

	options = () => {
		return {
			...SELECT2_DEFAULT_OPTIONS,
			...{
				tags: true,
				createTag: (params) => {
					const term = params.term.trim();

					if (term === '') return null;

					PHONE_FORMATTER.resolve(term);
					const phoneFormatted = PHONE_FORMATTER.value;

					if (!PHONE_REGEX.test(phoneFormatted)) return null;

					return {
						id: phoneFormatted,
						text: phoneFormatted,
					};
				},
			},
		};
	};

	// workaround because jquery doesn't emmit the native event change
	// see https://github.com/select2/select2/issues/1908
	dispatchChangeEvent = (event) => {
		const { target } = event;
		const nativeEvent = new Event('change', { bubbles: true });
		target.dispatchEvent(nativeEvent);
	};
}
