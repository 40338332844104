import CustomValidator from './CustomValidator';
import parsePhoneNumber from 'libphonenumber-js/mobile';

export default class CellphoneValidator extends CustomValidator {
	hasError() {
		const value = this._targetField.value;

		if (value === '') return false;

		const phoneNumber = parsePhoneNumber(value, 'BR');

		if (!phoneNumber) return true;

		const invalidNumbers = /^\+55(\d)\1{10}$/;

		return (
			!phoneNumber.isValid() ||
			phoneNumber.getType() !== 'MOBILE' ||
			invalidNumbers.test(phoneNumber.number)
		);
	}

	get defaultErrorMessage() {
		return `não é válido`;
	}
}
