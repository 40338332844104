import { Controller } from "@hotwired/stimulus";
import { Toast } from "../src";

export default class extends Controller {
  static values = {
    successMessage: {
      type: String,
      default: "Salvo com sucesso!",
    },
    errorMessage: {
      type: String,
      default: "Falha ao salvar. Corrija os erros e tente novamente.",
    },
    timer: Number,
  };

  showToast = (options) => {
    Toast.fire(options);
  };

  show = (event) => {
    let options = {};

    if (this.hasTimerValue) {
      options["timer"] = this.timerValue;
    }

    if (event.detail.success) {
      options["text"] = this.successMessageValue;
      options["icon"] = "success";
    } else {
      options["text"] = this.errorMessageValue;
      options["icon"] = "error";
    }

    this.showToast(options);
  };
}
