import { Controller } from '@hotwired/stimulus';
import 'bootstrap-table';
import 'bootstrap-table/dist/locale/bootstrap-table-pt-BR';
import '../javascript/src/bootstrapTableDataFormatters';

const SEARCH_DELAY_AFTER_INPUT_CHANGES = 500;

export default class extends Controller {
	static targets = ['table', 'toolbox', 'searchForm'];

	connect() {
		this.searchTimeout = 0;

		this.tableTarget.classList.add('table');

		this.setupTable();
	}

	setupTable = () => {
		const options = this.buildBootstrapTableOptions();
		$(this.tableTarget).bootstrapTable(options);
	};

	buildBootstrapTableOptions = () => {
		let defaultOptions = {
			locale: 'pt-BR',
			classes: 'table-bordered table-hover table-striped table-row-clickable',
			dataField: 'data',
			pagination: 'data',
			sidePagination: 'server',
			totalField: 'count',
			toolbarAlign: 'right',
			queryParamsType: '',
			buttonsAlign: 'left',
			buttonsClass: 'primary',
			queryParams: this.queryParams,
			onSort: this.onSort,
			onPageChange: this.onPageChange,
			onRefresh: this.onRefresh,
			onClickRow: this.handleTableClickRow,
		};

		if (this.hasToolboxTarget) {
			defaultOptions['toolbar'] = '#' + this.toolboxTarget.getAttribute('id');
		}

		const elementOptions = this.tableTarget.dataset;

		return {
			...defaultOptions,
			...elementOptions,
		};
	};

	queryParams = (params) => {
		const { sortName, sortOrder, pageSize, pageNumber } = params;
		const filters = this.getFilters();

		const searchParams = {
			sort_by: sortName,
			sort_order: sortOrder,
			page: pageNumber,
			per_page: pageSize,
			...filters,
		};

		return searchParams;
	};

	getFilters = () => {
		if (!this.hasSearchFormTarget) {
			return {};
		}

		let filters = {};

		for (let [key, value] of new FormData(this.searchFormTarget)) {
			if (value) {
				if (key.endsWith('[]')) {
					filters[key] ||= [];
					filters[key].push(value);
				} else {
					filters[key] = value;
				}
			}
		}

		return filters;
	};

	refresh = (_event) => {
		clearTimeout(this.searchTimeout);

		this.searchTimeout = setTimeout(() => {
			$(this.tableTarget).bootstrapTable('refresh');
		}, SEARCH_DELAY_AFTER_INPUT_CHANGES);
	};

	updateUrlAndState = (urlSearchParams) => {
		const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;
		window.history.pushState({}, document.title, newUrl);
	};

	onRefresh = (params) => {
		console.debug('onRefresh', params);

		const nonEmptyParams = Object.fromEntries(Object.entries(params).filter(([_, v]) => v));
		const newUrlSearchParams = new URLSearchParams(nonEmptyParams);
		this.updateUrlAndState(newUrlSearchParams);
	};

	onSort = (field, direction) => {
		console.debug('onSort', field, direction);

		const currentQueryParams = new URLSearchParams(window.location.search);

		currentQueryParams.set('sort_by', field);
		currentQueryParams.set('sort_order', direction);

		this.updateUrlAndState(currentQueryParams);
	};

	onPageChange = (pageNumber, pageSize) => {
		console.debug('onPageChange', pageNumber, pageSize);

		const currentQueryParams = new URLSearchParams(window.location.search);
		currentQueryParams.set('page', pageNumber);
		currentQueryParams.set('per_page', pageSize);
		this.updateUrlAndState(currentQueryParams);
	};

	handleTableClickRow = (row, element, _field) => {
		let url;
		const rowDataSet = $(element).data();

		// when json
		if (row['url']) {
			url = row['url'];
		}

		if (rowDataSet['url']) {
			url = rowDataSet['url'];
		}

		if (url) {
			const normalized_url = url.replace('.json', '');
			window.location = normalized_url;
		}
	};
}
