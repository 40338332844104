import { Controller } from '@hotwired/stimulus';
import dayjs from 'dayjs';
import Litepicker from 'litepicker';

const USER_DATE_FORMAT = 'DD/MM/YY';

export default class extends Controller {
	static targets = ['label'];

	static values = {
		startDate: String,
		startDateInputSelector: String,
		endDate: String,
		endDateInputSelector: String,
		reloadPageOnChange: Boolean,
	};

	connect() {
		this.findInputs();

		console.debug('start and end dates', this.startDateValue, this.endDateValue);
		console.debug(
			'inputs selectors',
			this.startDateInputSelectorValue,
			this.endDateInputSelectorValue
		);

		this.setupDatePicker(this.startDateValue, this.endDateValue);

		if (this.startDate && this.endDate) {
			this.updateMenu();
		}
	}

	get startDate() {
		if (this.datePickerInstance.getStartDate()) {
			return this.datePickerInstance.getStartDate().dateInstance;
		} else {
			return null;
		}
	}

	get endDate() {
		if (this.datePickerInstance.getEndDate()) {
			return this.datePickerInstance.getEndDate().dateInstance;
		} else {
			return null;
		}
	}

	setupDatePicker = (startDate, endDate) => {
		const ranges = this.calculateDateRanges();
		const settings = {
			element: this.element,
			startDate,
			endDate,
			lang: 'pt-BR',
			singleMode: false,
			buttonText: {
				apply: 'Selecionar',
				cancel: 'Cancelar',
			},
			tooltipText: {
				one: 'dia',
				other: 'dias',
			},
			dropdowns: {
				minYear: 2021,
				maxYear: null,
				months: true,
				years: true,
			},
			plugins: ['ranges'],
			ranges: {
				position: 'left',
				customRanges: {
					Hoje: ranges.today,
					Ontem: ranges.yesterday,
					'Semana atual': ranges.currentWeek,
					'Semana passada': ranges.lastWeek,
					'Últimos 15 dias': ranges.last15days,
					'Últimos 30 dias': ranges.last30days,
					'Mês atual': ranges.currentMonth,
					'Mês passado': ranges.lastMonth,
				},
			},

			setup: (picker) => {
				picker.on('selected', this.handleDateRangeSelected);
			},
		};

		this.datePickerInstance = new Litepicker(settings);
	};

	calculateDateRanges = () => {
		return {
			today: [new Date(), new Date()],
			yesterday: [dayjs().subtract(1, 'days').toDate(), dayjs().subtract(1, 'days').toDate()],
			currentWeek: [dayjs().startOf('week').toDate(), new Date()],
			lastWeek: [
				dayjs().subtract(1, 'week').startOf('week').toDate(),
				dayjs().subtract(1, 'week').endOf('week').toDate(),
			],
			last15days: [dayjs().subtract(15, 'days').toDate(), new Date()],
			last30days: [dayjs().subtract(30, 'days').toDate(), new Date()],
			currentMonth: [dayjs().startOf('month').toDate(), new Date()],
			lastMonth: [
				dayjs().subtract(1, 'month').startOf('month').toDate(),
				dayjs().subtract(1, 'month').endOf('month').toDate(),
			],
		};
	};

	handleDateRangeSelected = (_uiElement) => {
		this.updateMenu();

		if (this.reloadPageOnChangeValue) {
			this.reloadPage();
		} else {
			this.updateInputs();
		}
	};

	formatDate = (date, format = 'YYYY-MM-DD') => dayjs(date).format(format);

	findInputs = () => {
		if (this.startDateInputSelectorValue) {
			this.startDateInputElement = document.querySelector(this.startDateInputSelectorValue);
		}

		if (this.endDateInputSelectorValue) {
			this.endDateInputElement = document.querySelector(this.endDateInputSelectorValue);
		}
	};

	updateInputs = () => {
		if (!this.startDateInputElement || !this.endDateInputElement) {
			return;
		}

		// update inputs
		this.startDateInputElement.value = this.formatDate(this.startDate);
		this.endDateInputElement.value = this.formatDate(this.endDate);

		// notify changes
		this.startDateInputElement.dispatchEvent(new Event('change', { bubbles: true }));
		this.endDateInputElement.dispatchEvent(new Event('change', { bubbles: true }));
	};

	updateMenu = () => {
		const startDateFormatted = this.formatDate(this.startDate, USER_DATE_FORMAT);
		const endDateFormatted = this.formatDate(this.endDate, USER_DATE_FORMAT);

		// render date range into button
		this.labelTarget.innerHTML = `${startDateFormatted} - ${endDateFormatted}`;
	};

	reloadPage = () => {
		const urlEditor = new URLSearchParams();

		const startDateFormatted = this.formatDate(this.startDate);
		urlEditor.set('start_date', startDateFormatted);

		const endDateFormatted = this.formatDate(this.endDate);
		urlEditor.set('end_date', endDateFormatted);

		window.location.search = urlEditor.toString();
	};
}
