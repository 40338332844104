import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	connect() {
		this.element.addEventListener('ajax:success', (_data) => this.updateView(this.element));
	}

	destroy = (event) => {
		const { target } = event;

		event.preventDefault();
		event.stopPropagation();

		this.updateView(target);
	};

	updateView = (element) => {
		$('[data-toggle="tooltip"]').tooltip('hide');

		element.closest('[data-attachment-id]').remove();
	};
}
