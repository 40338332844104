import { Controller } from '@hotwired/stimulus';
import { FormValidator } from '../src/helpers';
export default class extends Controller {
	static values = {
		disableSubmit: Boolean,
	};

	connect() {
		this.setup();

		this.submitButton = this.element.querySelector('button[type=submit]');
		if (this.disableSubmitValue) {
			this.checkButtonSubmitState();
			this.element.addEventListener('input', this.checkButtonSubmitState);
		}
	}

	setup = () => {
		this.formValidatorInstance = new FormValidator(this.element);
		this.element.addEventListener('form:fieldsChanged', this.handleFormChanges);
	};

	handleFormChanges = () => {
		this.formValidatorInstance.handleFormChanges();
	};

	checkButtonSubmitState = () => {
		this.submitButton.disabled = !this.formValidatorInstance.validateForm();
	};

	submit = (_event) => {
		this.element.submit();
	};
}
