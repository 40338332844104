import { Controller } from "@hotwired/stimulus";

import IMask from "imask";

const MASK_SETTINGS = {
  mask: [
    {
      mask: "000.000.000-00",
    },
    {
      mask: "00.000.000/0000-00",
    },
  ],
};

export default class extends Controller {
  connect() {
    this.imaskInstance = this.setup();
  }

  disconnect() {
    if (this.imaskInstance) {
      this.imaskInstance.destroy();
    }
  }

  setup = () => {
    this.imaskInstance = new IMask(this.element, MASK_SETTINGS);
    const triggerValidation = (currentEvent) => {
      if (currentEvent) {
        const fieldValidateEvent = new Event("field:validate");
        currentEvent.target.dispatchEvent(fieldValidateEvent);
      }
    };
    this.imaskInstance.on("complete", triggerValidation);

    //add listener to update mask when change field programmatically
    this.element.addEventListener("field:changed", this.updateMask);
  };

  update = (_event) => {
    if (this.imaskInstance) {
      this.imaskInstance.updateValue();
    }
  };
}
