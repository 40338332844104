import CustomValidator from "./CustomValidator";
import { validateBr } from "js-brasil";

export default class PersonalDocumentValidator extends CustomValidator {
  hasError() {
    return !validateBr.cpf(this._targetField.value);
  }

  get defaultErrorMessage() {
    return `não é um CPF válido`;
  }
}
