import { Controller } from "@hotwired/stimulus";
import consumer from "../channels/consumer";
import dayjs from "../src/dayjs";

export default class extends Controller {
  static targets = [
    "unreadCounter",
    "notificationTemplate",
    "notificationsWrapper",
    "noNotificationsMessage",
  ];

  connect() {
    this.channel = this.subscribeChannel();

    if (this.hasNotificationBrowserSupport() && this.shouldRequestPermission()) {
      Notification.requestPermission();
    }
  }

  subscribeChannel() {
    return consumer.subscriptions.create("NotificationsChannel", {
      connected: this._connected.bind(this),
      received: this._received.bind(this),
    });
  }

  _connected() {
    this.requestStatus();
  }

  _received(data) {
    const { event, ...payload } = data;

    switch (event) {
      case "new_notification":
        this.requestStatus();

        if (this.shouldNotifyViaBrowser()) {
          this.createNativeNotification("Você recebeu uma nova notificação!");
        };
        break;
      case "status":
        const {
          total_unread: totalRead,
          last_unread_notifications: notifications,
        } = payload;

        if (totalRead > 0) {
          this.updateNotificationButtonCounter(totalRead);
          this.renderNotifications(notifications);
        }
        break;
      default:
        console.log("received message from notifications channel", data);
        break;
    }
  }

  requestStatus = () => this.channel.perform("status");

  updateNotificationButtonCounter = (total) => {
    this.unreadCounterTarget.innerHTML = total;
  };

  renderNotifications = (notifications) => {
    // remove current notifications
    this.notificationsWrapperTarget
      .querySelectorAll("[data-notification-item]")
      .forEach((element) => {
        this.notificationsWrapperTarget.removeChild(element);
      });

    // toggle "no notifications message" if necessary
    if (notifications.length === 0) {
      this.noNotificationsMessageTarget.style.display = "block";
    } else {
      this.noNotificationsMessageTarget.style.display = "none";
    }

    // render new notifications
    notifications.forEach((notification) => {
      const { title, created_at: createdAt, url } = notification;

      const clonedTemplate =
        this.notificationTemplateTarget.content.cloneNode(true);
      const linkElement = clonedTemplate.querySelector("a[href='#']");
      linkElement.href = url;

      const titleElement = clonedTemplate.querySelector(
        "[data-notification-title]"
      );
      titleElement.textContent = title;

      const timestampElement = clonedTemplate.querySelector(
        "[data-notification-timestamp]"
      );
      timestampElement.textContent = dayjs().to(createdAt);

      const allNotificationsLink =
        this.notificationsWrapperTarget.querySelector(
          ".dropdown-notifications-footer"
        );
      this.notificationsWrapperTarget.insertBefore(
        clonedTemplate,
        allNotificationsLink
      );
    });
  };

  hasNotificationBrowserSupport = () => {
    return "Notification" in window;
  };

  shouldRequestPermission = () => Notification.permission == "default";

  hasPermissionToNotifyViaBrowser = () => Notification.permission == "granted";

  isUserInCurrentTab = () => document.visibilityState == "visible";

  shouldNotifyViaBrowser = () => {
    return (
      this.isUserInCurrentTab() == false &&
      this.hasNotificationBrowserSupport() &&
      this.hasPermissionToNotifyViaBrowser()
    );
  }

  createNativeNotification = (body) => {
    const title = "SIGAGR";
    const timeout = 5000;
    const settings = {
      body,
      icon: "https://imgur.com/V6xWrep.png", //siga logo
    };

    const notification = new Notification(title, settings);

    notification.onclick = () => {
      window.parent.focus();
      notification.close();
    }

    setTimeout(notification.close(), timeout);
  };
}
