import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["memberFormWrapper", "newMemberInput"];

  connect() {
    this.form = this.element.closest("form");
  }

  addMember = (event) => {
    event.preventDefault();

    const formTemplate = event.target.parentElement.querySelector("[data-has-many-template-container]");
    const { id, fields } = formTemplate.dataset;
    const nextId = Math.floor(Math.random() * 1000);

    const newFields = fields.replace(new RegExp(id, "g"), nextId);

    this.memberFormWrapperTarget.insertAdjacentHTML("beforebegin", newFields);

    this.form.dispatchEvent(new Event("form:fieldsChanged"));
  };

  removeMember = (event) => {
    event.preventDefault();

    const deletedMemberWrapper = event.target.closest("[data-member-wrapper]");
    const destroyInput = deletedMemberWrapper.querySelector("input[id$='_destroy']");

    // setting record as destroyed
    destroyInput.value = "true";

    // hidding element in 500ms
    setTimeout(() => deletedMemberWrapper.style.display = "none", 500)
  };

  removeMemberElement = (listWrapper, item) => listWrapper.removeChild(item)
}
