import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    url: String
  }

  read = (_event) => {
    Rails.ajax({
      type: "put",
      url: this.urlValue
    })
  }
}
