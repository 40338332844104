// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import RailsUJS from "@rails/ujs";

RailsUJS.start();

import "controllers";
import jQuery from "jquery";
import "bootstrap";
import "trix";
import "@rails/actiontext";
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "chartkick/chart.js";
import { Turbo  } from "@hotwired/turbo-rails"

Turbo.session.drive = false

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
// require.context('../images', true)
// require.context('../fonts', true)
// const imagePath = (name) => images(name, true)

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import "src/themeSetup"
