import { Controller } from "@hotwired/stimulus";

import "select2";
import "select2/dist/js/i18n/pt-BR";

const SELECT2_DEFAULT_OPTIONS = {
  theme: "bootstrap4",
  language: "pt-BR",
  width: "style",
};

const PAGINATION_LIMIT = 10;

const AUTOCOMPLETES_SETTINGS = {
  goods: {
    tags: true,
  },
  local: {},
  cities: {
    tags: true,
    selectOnClose: false,
    ajax: {
      url: "/cities",
      dataType: "json",
      delay: 500,
      data: (params) => {
        const query = {
          term: params.term,
          sort_by: "name",
          sort_order: "asc",
          per_page: PAGINATION_LIMIT,
          page: params.page,
        };

        return query;
      },
      processResults: ({ data }) => {
        const results = data.map(({ name, state }) => {
          const value = `${name} - ${state}`;

          return {
            id: value,
            text: value,
          };
        });

        const pagination = { more: data.length == PAGINATION_LIMIT };

        return { results, pagination };
      },
    },
  },
  customers: {
    ajax: {
      url: "/customers",
      dataType: "json",
      delay: 500,
      data: (params) => {
        const query = {
          "search[q]": params.term,
          sort_by: "name",
          sort_order: "asc",
          per_page: PAGINATION_LIMIT,
          page: params.page,
        };

        return query;
      },
      processResults: ({ data }) => {
        const results = data.map(({ id, name }) => ({
          id,
          text: name,
        }));
        const pagination = { more: data.length == PAGINATION_LIMIT };

        return { results, pagination };
      },
    },
  },
  customerBranches: {
    ajax: {
      url: "",
      dataType: "json",
      delay: 500,
      data: (params) => {
        const query = {
          term: params.term,
          sort_by: "name",
          sort_order: "asc",
          per_page: PAGINATION_LIMIT,
          page: params.page,
        };

        return query;
      },
      processResults: ({ data }) => {
        const results = data.map(({ id, name }) => ({
          id,
          text: name,
        }));
        const pagination = { more: data.length == PAGINATION_LIMIT };

        return { results, pagination };
      },
    },
  },
  users: {
    ajax: {
      url: "/users",
      dataType: "json",
      delay: 500,
      data: (params) => {
        const query = {
          "search[q]": params.term,
          sort_by: "fullname",
          sort_order: "asc",
          per_page: PAGINATION_LIMIT,
          page: params.page,
        };

        return query;
      },
      processResults: ({ data }) => {
        const results = data.map(({ id, fullname }) => ({
          id,
          text: fullname,
        }));
        const pagination = { more: data.length == PAGINATION_LIMIT };

        return { results, pagination };
      },
    },
  },
};

export default class extends Controller {
  static targets = ["field"];

  connect() {
    this.fieldTargets.forEach((element) => this.setup(element));
  }

  setup = (element) => {
    const autocompleteType = element.dataset["autocompleteType"];
    let options = AUTOCOMPLETES_SETTINGS[autocompleteType];

    const url = element.dataset["autocompleteUrl"];
    if (url) {
      options["ajax"]["url"] = url;
    }

    const placeholder = element.getAttribute("placeholder");
    if (placeholder) {
      options["placeholder"] = placeholder;
    }

    options["dropdownParent"] = element.closest(".modal-body") || $("main");

    const mergedOptions = { ...SELECT2_DEFAULT_OPTIONS, ...options };

    $(element)
      .select2(mergedOptions)
      .on("select2:open", this.onOpenDropdown)
      .on("select2:select", this.dispatchChangeEvent)
      .on("select2:unselect", this.dispatchChangeEvent)
      .on("select2:clear", this.dispatchChangeEvent);
  };

  update = (event) => {
    const { target } = event;
    const autocompleteSelector = target.dataset["autocompleteDependencyTarget"];

    const autocompleteElement = document.querySelector(autocompleteSelector);
    $(autocompleteElement).select2("destroy");

    // cleanup the field
    autocompleteElement.value = "";

    // update autocomplete url if necessary
    const autocompleteBaseUrl =
      autocompleteElement.dataset["autocompleteBaseUrl"];
    if (autocompleteBaseUrl) {
      autocompleteElement.dataset["autocompleteUrl"] =
        autocompleteBaseUrl.replace("REPLACE_VALUE", target.value);
    }
    // update autocomplete
    this.setup(autocompleteElement);
  };

  onOpenDropdown = (event) => {
    //workaround to focus on search input when open dropdown menu
    const searchInput = document.querySelector("input.select2-search__field");

    if (searchInput) {
      searchInput.focus();
    }
  };

  // workaround because jquery doesn't emmit the native event change
  // see https://github.com/select2/select2/issues/1908
  dispatchChangeEvent = (event) => {
    const { target } = event;
    const nativeEvent = new Event("change", { bubbles: true });
    target.dispatchEvent(nativeEvent);
  };
}
