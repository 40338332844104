import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";

// setting brazilian locale
dayjs.locale("pt-br");

//enabling plugins
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

export default dayjs;
