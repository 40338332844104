import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import { Toast } from "../../javascript/src";

export default class extends Controller {
  static targets = ["statusBadge", "deleteButton"];

  connect() {
    this.bindListenders();
  }

  bindListenders = () => {
    if (this.hasDeleteButtonTarget) {
      this.deleteButtonTarget.addEventListener(
        "ajax:success",
        this.handleDeleteSuccess
      );
      this.deleteButtonTarget.addEventListener(
        "ajax:error",
        this.handleRequestError
      );
    }
  };

  handleUpdateSuccess = (data) => {
    const { solved } = data;
    const statusLabel = solved ? "Corrigido" : "Pendente";
    const statusColor = solved ? "green" : "red";

    if (this.statusBadgeTarget) {
      this.statusBadgeTarget.innerText = statusLabel;
      this.statusBadgeTarget.classList.remove("badge-green", "badge-red");
      this.statusBadgeTarget.classList.add(`badge-${statusColor}`);
    }
  };

  handleDeleteSuccess = (_e) => {
    const parentElement = this.element.parentNode;

    $('[data-toggle="tooltip"]').tooltip("hide");

    parentElement.removeChild(this.element);

    Toast.fire({
      text: "Pendência removida com sucesso.",
      icon: "success",
    });
  };

  handleRequestError = (data) => {
    const { message } = data;
    const text = message || "Não foi possível concluir a operação.";
    Toast.fire({ text, icon: "error" });
  };

  toggle = (event) => {
    const solved = event.target.checked;
    const form = event.target.closest("form");
    const url = form.getAttribute("action");
    const params = {
      issue: {
        solved,
      },
    };

    Rails.ajax({
      type: "put",
      url,
      beforeSend(xhr, options) {
        xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
        options.data = JSON.stringify(params);
        return true;
      },
      success: this.handleUpdateSuccess,
      error: this.handleRequestError,
    });
  };
}
