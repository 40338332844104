import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { validateBr } from "js-brasil";
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    url: String,
    customerId: String,
  };

  input = (event) => {
    const document = event.target.value;

    if (validateBr.cpf(document)) {
      this.checkValidAnalysisForDriver();
    }
  };

  showAlert = () => {
    Swal.fire({
      icon: "warning",
      title: "Motorista com análise válida",
      text: "Este motorista já foi analisado e sua análise ainda é válida. Deseja continuar?",
      showCancelButton: true,
      confirmButtonText: "Voltar",
      cancelButtonText: "Continuar",
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        window.location = "/driver_analyses";
      } else {
        this.element.setAttribute("readOnly", true);
      }
    });
  };

  checkValidAnalysisForDriver = () => {
    const driverPersonalDocument = this.element.value;
    const customerId = this.customerIdValue;
    const params = new URLSearchParams({
      "driver_analysis[customer_id]": customerId,
      "driver_analysis[driver_personal_document]": driverPersonalDocument,
    });
    const url = `${this.urlValue}?${params}`;

    console.log(url);

    Rails.ajax({
      type: "GET",
      url,
      success: (data) => {
        const { total } = data;
        if (total > 0) {
          this.showAlert();
        }
      },
    });
  };
}
