import { Controller } from '@hotwired/stimulus';
import { trailersPerTruckType } from '../../src/helpers';

export default class extends Controller {
	static targets = ['vehicleTypeSelector', 'trailer'];

	connect() {
		const vehicleType = this.vehicleTypeSelectorTarget.value;
		this.applyTrailerFieldsChanges(vehicleType);
	}

	vehicleTypeChanged = (event) => {
		const vehicleType = event.target.value;
		this.applyTrailerFieldsChanges(vehicleType);
	};

	applyTrailerFieldsChanges = (vehicleType) => {
		const countTrailers = trailersPerTruckType(vehicleType);

		this.trailerTargets.forEach((trailer, index) => {
			const trailerPosition = index + 1;

			if (trailerPosition <= countTrailers) {
				trailer.disabled = false;
			} else {
				trailer.value = '';
				trailer.disabled = true;
			}
		});
	};
}
