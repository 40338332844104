import { Controller } from "@hotwired/stimulus";

const RELOAD_TIMEOUT_IN_MILLISECONDS = 15000;

export default class extends Controller {
  connect() {
   this.timeout = setTimeout(this.reload, RELOAD_TIMEOUT_IN_MILLISECONDS)
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  reload = () => window.location.reload();
}
