export default class HasManyForm {
  constructor(rootNode) {
    this.rootNode = rootNode;
    this.form = rootNode.closest("form");

    const newElementButtonId = this.rootNode.dataset["hasManyNewElementButton"];
    this.newElementButton = document.getElementById(newElementButtonId);
    this.templateContainer = rootNode.querySelector(
      "[data-has-many-template-container]"
    );

    this.initialize();
  }

  initialize() {
    this.newElementButton.addEventListener("click", this.handleNewClick);
    this.findAllDestroyButtons().forEach((button) =>
      button.addEventListener("click", this.handleDeleteClick)
    );
  }

  findAllDestroyButtons = () =>
    this.rootNode.querySelectorAll("[data-has-many-destroy-button]");

  handleNewClick = (event) => {
    event.preventDefault();

    this.addElementForm();
    this.form.dispatchEvent(new Event("form:fieldsChanged"));
  };

  handleDeleteClick = (event) => {
    event.preventDefault();
    const wrapper = event.target.closest("[data-has-many-item-wrapper]");
    this.deleteElement(wrapper);
    this.form.dispatchEvent(new Event("form:fieldsChanged"));
  };

  addElementForm = () => {
    const { id, fields } = this.templateContainer.dataset;
    const time = new Date().getTime();
    const newFields = fields.replace(new RegExp(id, "g"), time);

    this.rootNode.insertAdjacentHTML("beforeend", newFields);

    // bind on click handler to destroy button
    const destroyButton = this.rootNode.lastElementChild.querySelector("[data-has-many-destroy-button]")
    destroyButton.addEventListener("click", this.handleDeleteClick);
  };

  deleteElement = (wrapper) => {
    const itemIdInput = wrapper.querySelector("[data-has-many-item-id]");
    const elementDestroyInput = wrapper.querySelector(
      "[data-has-many-item-destroy]"
    );

    if (itemIdInput && itemIdInput.value) {
      if (elementDestroyInput) {
        elementDestroyInput.value = "true";
        elementDestroyInput.setAttribute(
          "data-has-many-item-marked-as-destroyed",
          ""
        );
        wrapper.style.display = "none";
      } else {
        console.warn("destroy input not found");
      }
    } else {
      this.rootNode.removeChild(wrapper);
    }
  };
}
