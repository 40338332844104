document.addEventListener("DOMContentLoaded", function () {

  // Enable Bootstrap tooltips via data-attributes globally
  $('[data-toggle="tooltip"]').tooltip();

  // Enable Bootstrap popovers via data-attributes globally
  $('[data-toggle="popover"]').popover();

  $(".popover-dismiss").popover({
    trigger: "focus",
  });

  // Add active state to sidbar nav links
  var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
  $("#layoutSidenav_nav .sidenav a.nav-link").each(function () {
    if (this.href === path) {
      $(this).addClass("active");
    }
  });

  // Toggle the side navigation
  $("#sidebarToggle").on("click", function (e) {
    e.preventDefault();
    $("body").toggleClass("sidenav-toggled");
  });

  // Activate Bootstrap scrollspy for the sticky nav component
  $("body").scrollspy({
    target: "#stickyNav",
    offset: 82,
  });

  // Scrolls to an offset anchor when a sticky nav link is clicked
  $('.nav-sticky a.nav-link[href*="#"]:not([href="#"])').click(function () {
    if (
      location.pathname.replace(/^\//, "") ==
        this.pathname.replace(/^\//, "") &&
      location.hostname == this.hostname
    ) {
      var target = $(this.hash);
      target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
      if (target.length) {
        $("html, body").animate(
          {
            scrollTop: target.offset().top - 81,
          },
          200
        );
        return false;
      }
    }
  });

  // Click to collapse responsive sidebar
  $("#layoutSidenav_content").click(function () {
    const BOOTSTRAP_LG_WIDTH = 992;
    if (window.innerWidth >= BOOTSTRAP_LG_WIDTH) {
      return;
    }
    if ($("body").hasClass("sidenav-toggled")) {
      $("body").toggleClass("sidenav-toggled");
    }
  });

  document
    .querySelectorAll(".table-row-clickable tbody tr > td:not(.actions)")
    .forEach((element) => {
      element.addEventListener("click", function (event) {
        event.preventDefault();
        const { href } = event.target.parentElement.dataset;

        if (href) {
          window.location.assign(href);
        }
      });
    });
});
