import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  export = (event) => {
    event.preventDefault();

    const exportUrl = event.target.href;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const exportUrlWithFilters = `${exportUrl}?${urlSearchParams.toString()}`;
    window.location.href = exportUrlWithFilters;
  };
}
