import { Controller } from "@hotwired/stimulus";
import Toast from "../../../javascript/src/toast";
export default class extends Controller {
  static targets = ["checkbox"];

  connect() {
    if (this.checkboxTarget.checked) {
      this.applyStyleDone();
    }
  }

  applyStyleDone = () => {
    this.element.classList.add("text-muted");
    this.element.querySelector("label").classList.add("text-line-through");
  };

  applyStylePending = () => {
    this.element.classList.remove("text-muted");
    this.element.querySelector("label").classList.remove("text-line-through");
  };

  submit = (event) => {
    event.preventDefault();

    console.debug("submit", event);

    const { target } = event;

    target.closest("form").requestSubmit();
  };

  updateComponent = (event) => {
    console.debug("updateComponent", event);

    Toast.fire({
      icon: "success",
      text: "Item atualizado com sucesso.",
    });

    if (this.checkboxTarget.checked) {
      this.applyStyleDone();
    } else {
      this.applyStylePending();
    }
  };

  renderError = (event) => {
    console.debug("renderError", event);

    const [_data, _status, xhr] = event.detail;

    console.debug("xhr object", xhr);

    switch (xhr.status) {
      case 401:
        Toast.fire({
          icon: "error",
          text: "Sua sessão expirou.",
        });

        break;
      case 403:
        Toast.fire({ icon: "error", text: "Acesso negado." });
        break;
      default:
        Toast.fire({
          icon: "error",
          text: "Falha ao atualizar o item. Atualize a tela e tente novamente.",
        });
        break;
    }
  };
}
