import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "fileInput", "imagePreview" ]

  open = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.fileInputTarget.click();
  }

  change = (_event) => {
    const files = this.fileInputTarget.files[0];
    const previewTarget = this.imagePreviewTarget;

    if (files) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", function () {
        previewTarget.setAttribute("src", this.result);
      })
      fileReader.readAsDataURL(files);
    }
  }
}
