import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	connect() {
		this.ensureCase();
	}

	onChange = (_event) => {
		this.ensureCase();
	};

	ensureCase = () => {
		this.element.value = this.element.value.toUpperCase();
	};
}
