import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	destroy = (event) => {
		event.preventDefault();
		event.stopPropagation();

		this.element.remove();
	};
}
