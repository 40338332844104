import { Controller } from '@hotwired/stimulus';
import { dayjs, Toast } from '../javascript/src';
import consumer from '../javascript/channels/consumer';
import Rails from '@rails/ujs';

const DATETIME_FORMAT = 'DD/MM/YYYY [às] HH:mm';
const CANCELABLE_STATUSES = ['scheduled', 'running', 'waiting'];
const SPINNER_HTML = '<span class="spinner-border spinner-border-sm" role="status"></span>';

export default class extends Controller {
	static targets = ['button', 'statusBadge'];

	static values = {
		currentStatus: String,
		lastChange: String,
		statusLabels: Object,
		statusColors: Object,
	};

	connect() {
		const { inquiryId } = this.element.dataset;

		this.updateComponent();

		this.channel = this.subscribeChannel(inquiryId);
	}

	get isCancelable() {
		return CANCELABLE_STATUSES.includes(this.currentStatusValue);
	}

	handleButtonClick = (jsEvent) => {
		jsEvent.preventDefault();

		const nextEvent = this.isCancelable ? 'cancel' : 'retry';

		this.updateInquiryRequest(nextEvent);
	};

	updateInquiryRequest = (nextEvent) => {
		const data = new FormData();
		data.append('event', nextEvent);

		Rails.ajax({
			url: this.buttonTarget.dataset.url,
			type: 'put',
			dataType: 'json',
			data,
			success: (data) => {
				const { message } = data;

				this.updateValues(data);

				Toast.fire({
					text: message,
					icon: 'success',
				});
			},
			error: (data) => {
				const { message } = data;
				const text = message || 'Não foi possível concluir a operação.';
				Toast.fire({ text, icon: 'error' });
			},
		});
	};

	updateValues = (data) => {
		const { status, updated_at: updatedAt } = data;

		this.currentStatusValue = status;
		this.lastChangeValue = updatedAt;
	};

	updateComponent = () => {
		this.updateButton();
		this.updateBadge();
		this.refreshLastChangeTooltip();
	};

	updateBadge = () => {
		const label = this.statusLabelsValue[this.currentStatusValue];
		const color = this.statusColorsValue[this.currentStatusValue];

		this.statusBadgeTarget.className = 'badge';
		this.statusBadgeTarget.classList.add(`badge-${color}`);
		this.statusBadgeTarget.innerText = label;
	};

	updateButton = () => {
		if (this.isCancelable && !this.buttonTarget.dataset['previousState']) {
			this.buttonTarget.dataset['previousState'] = this.buttonTarget.innerHTML;
			this.buttonTarget.innerHTML = SPINNER_HTML;
		}

		if (!this.isCancelable && this.buttonTarget.dataset['previousState']) {
			this.buttonTarget.innerHTML = this.buttonTarget.dataset['previousState'];
			delete this.buttonTarget.dataset['previousState'];
		}
	};

	refreshLastChangeTooltip = () => {
		const lastChangeFormatted = dayjs(this.lastChangeValue).format(DATETIME_FORMAT);
		$(this.statusBadgeTarget).tooltip('dispose');
		this.statusBadgeTarget.setAttribute('title', `Última atualização em ${lastChangeFormatted}`);
		$(this.statusBadgeTarget).tooltip();
	};

	subscribeChannel = (id) => {
		return consumer.subscriptions.create(
			{
				channel: 'InquiryChannel',
				id,
			},
			{
				received: this._received.bind(this),
			}
		);
	};

	_received(response) {
		const { _event, data } = response;

		this.updateValues(data);
		this.updateComponent();
	}
}
