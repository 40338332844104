import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import Cookies from "js-cookie";

export default class extends Controller {
  static values = {
    name: {
      type: String,
    },
    cookieName: {
      type: String,
      default: "report_download_finished",
    },
  };

  connect() {
    window.addEventListener("blur", () => {
      const reportDownloadFinished = Cookies.get(this.cookieNameValue);

      if (reportDownloadFinished == this.nameValue) {
        console.debug(
          "file download finished. Enabling form submit button and removing cookie."
        );

        Rails.enableElement(this.element);

        Cookies.remove(this.cookieNameValue);
      }
    });
  }
}
