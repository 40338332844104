
export default (vehicleType) => {
  switch (vehicleType) {
    case "Rodotrem":
      return 3;
    case "BI-Caçamba":
    case "BI-Trem":
      return 2;
    case "Baú":
    case "Container":
    case "LS":
    case "Sider":
    case "Tanque":
    case "Vanderléia":
      return 1;
    default:
      return 0;
  }
}
