import { Controller } from "@hotwired/stimulus";
import "bootstrap-table";
import "bootstrap-table/dist/locale/bootstrap-table-pt-BR";
import dayjs from "dayjs";
import Litepicker from "litepicker";
import "litepicker/dist/plugins/ranges";

export default class extends Controller {
  static targets = [
    "table",
    "searchInput",
    "startDateInput",
    "endDateInput",
    "dateRange",
    "filter",
    "advancedSearchForm",
  ];

  connect() {
    this.searchTimeout = 0;

    this.setupTable();

    if (this.hasDateRangeTarget) {
      this.setupDateRange();
    }
  }

  setupTable = () => {
    const options = this.buildTableOptions();
    $(this.tableTarget).bootstrapTable(options);
  };

  buildTableOptions = () => {
    const defaultOptions = {
      locale: "pt-BR",
      classes: "table-bordered table-hover table-striped table-row-clickable",
      onClickRow: this.handleTableClickRow,
    };

    const { url, ...elementOptions } = this.tableTarget.dataset;

    if (!url) return { ...defaultOptions, ...elementOptions };

    const tableWithRemoteDataOptions = {
      dataField: "data",
      pagination: "data",
      sidePagination: "server",
      totalField: "count",
      toolbarAlign: "right",
      queryParamsType: "",
      buttonsAlign: "left",
      buttonsClass: "primary",
      queryParams: this.queryParams,
    };

    return {
      ...defaultOptions,
      ...tableWithRemoteDataOptions,
      ...elementOptions,
    };
  };

  setupDateRange = () => {
    const dateFormat = "DD/MM/YY";
    const startDate = dayjs().subtract(15, "days").toDate();
    const endDate = dayjs().toDate();

    new Litepicker({
      element: this.dateRangeTarget,
      startDate,
      endDate,
      singleMode: false,
      lang: "pt-BR",
      format: dateFormat,
      buttonText: {
        apply: "Selecionar",
        cancel: "Cancelar",
      },
      tooltipText: {
        one: "dia",
        other: "dias",
      },
      dropdowns: {
        minYear: 2021,
        maxYear: null,
        months: true,
        years: true,
      },
      plugins: ["ranges"],
      ranges: {
        position: "right",
        customRanges: {
          Hoje: [new Date(), new Date()],
          Ontem: [
            dayjs().subtract(1, "days").toDate(),
            dayjs().subtract(1, "days").toDate(),
          ],
          "Últimos 15 dias": [
            dayjs().subtract(15, "days").toDate(),
            dayjs().toDate(),
          ],
          "Últimos 30 dias": [
            dayjs().subtract(30, "days").toDate(),
            dayjs().toDate(),
          ],
          "Mês atual": [dayjs().startOf("month").toDate(), dayjs().toDate()],
          "Mês passado": [
            dayjs().subtract(1, "month").startOf("month").toDate(),
            dayjs().subtract(1, "month").endOf("month").toDate(),
          ],
        },
      },
      setup: (picker) => {
        picker.on("selected", (_uiElement) => {
          const startDate = picker.getStartDate().dateInstance;
          const endDate = picker.getEndDate().dateInstance;
          const startDateFormatted = dayjs(startDate).format(dateFormat);
          const endDateFormatted = dayjs(endDate).format(dateFormat);

          // render into button
          this.dateRangeTarget.querySelector(
            "span"
          ).innerHTML = `${startDateFormatted} - ${endDateFormatted}`;

          this.startDateInputTarget.value = dayjs(startDate)
            .startOf("day")
            .format();
          this.endDateInputTarget.value = dayjs(endDate).endOf("day").format();

          const event = new Event("change", { bubbles: true });
          this.dateRangeTarget.dispatchEvent(event);
        });
      },
    });
  };

  queryParams = (params) => {
    const { sortName, sortOrder, pageSize, pageNumber } = params;
    let startDate, endDate;
    let advancedFilters = {};

    let term;

    if (this.hasSearchInputTarget) {
      term = this.searchInputTarget.value.trim();
    }

    let basicFilters = {};

    this.filterTargets.forEach((filter) => {
      if (filter.name.endsWith("[]")) {
        basicFilters[filter.name] ||= [];
        basicFilters[filter.name].push(filter.value);
      } else {
        basicFilters[filter.name] = filter.value;
      }
    });

    // parse date range
    if (this.hasStartDateInputTarget && this.hasEndDateInputTarget) {
      const startDateSelected = this.startDateInputTarget.value.trim();
      const endDateSelected = this.endDateInputTarget.value.trim();

      if (startDateSelected && endDateSelected) {
        startDate = dayjs(startDateSelected).toDate();
        endDate = dayjs(endDateSelected).toDate();
      }
    }

    // add advanced filters
    if (this.hasAdvancedSearchFormTarget) {
      advancedFilters = this.advancedFilters();
    }

    const searchParams = {
      term,
      start_date: startDate,
      end_date: endDate,
      sort_by: sortName,
      sort_order: sortOrder,
      page: pageNumber,
      per_page: pageSize,
      ...basicFilters,
      ...advancedFilters,
    };

    return searchParams;
  };

  advancedFilters = () => {
    let advancedFilters = {};

    for (let [key, value] of new FormData(this.advancedSearchFormTarget)) {
      if (value) {
        if (key.endsWith("[]")) {
          advancedFilters[key] ||= [];
          advancedFilters[key].push(value);
        } else {
          advancedFilters[key] = value;
        }
      }
    }

    return advancedFilters;
  };

  filterChanged = (_event) => {
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      $(this.tableTarget).bootstrapTable("refresh");
    }, 500);
  };

  handleTableClickRow = (row, element, field) => {
    let url;
    const rowDataSet = $(element).data();

    // when json
    if (row["url"]) {
      url = row["url"];
    }

    if (rowDataSet["url"]) {
      url = rowDataSet["url"];
    }

    if (url) {
      const normalized_url = url.replace(".json", "");
      window.location = normalized_url;
    }
  };
}
