import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import { Toast } from "../src";

export default class extends Controller {
  submit = (event) => {
    event.preventDefault();

    const { action, method } = this.element;
    const data = new FormData(this.element);

    Rails.ajax({
      type: method,
      dataType: "json",
      accept: "json",
      url: action,
      data,
      success: (data) => {
        const { message } = data;
        Toast.fire({ text: message, icon: "success" });
      },
      error: (data) => {
        const { message } = data;
        const text = message || "Não foi possível salvar as alterações.";
        Toast.fire({ text, icon: "error" });
      },
    });
  };
}
