export default class CustomValidator {
  constructor(targetField, options) {
    this._targetField = targetField;

    if (options) {
      this._options = JSON.parse(options);
      this._customErrorMessage = this._options["message"];
    }
  }

  initialize() {}

  hasError() { return false; }

  get errorMessage() {
    return this._customErrorMessage || this.defaultErrorMessage;
  }

  get defaultErrorMessage() {
    return "not implemented";
  }
}
