import { Controller } from "@hotwired/stimulus";
import dayjs from "../src/dayjs";

const DATETIME_FORMAT = "DD/MM/YYYY HH:mm";

export default class extends Controller {
  static targets = ["expiresAtInput"];

  change = (event) => {
    const { value, dataset } = event.target;

    if (value) {
      const requestedAtString = dataset.requestedAt;
      const requestedAt = dayjs(requestedAtString);
      const expiredAt = requestedAt.add(value, "day");

      this.expiresAtInputTarget.value = expiredAt.format(DATETIME_FORMAT);
    } else {
      this.expiresAtInputTarget.value = "";
    }

    // notify masks controller to force mask update
    this.expiresAtInputTarget.dispatchEvent(new Event("field:changed"));
  };
}
