import dayjs from './dayjs';

// global functions used by bootstrap table on data-formatter attributes

const DRIVER_ANALYSIS_STATE_LABELS = {
	draft: 'Aguardando preenchimento',
	awaiting_analysis: 'Aguardando análise',
	awaiting_reanalysis: 'Aguardando reanálise',
	under_analysis: 'Em análise',
	pending: 'Com pendências',
	approved: 'Aprovada',
	authorized_by_manager: 'Autorizado pelo gerente',
	denied: 'Erro cadastral',
};

const DRIVER_ANALYSIS_STATE_COLORS = {
	draft: 'blank',
	awaiting_analysis: 'yellow',
	awaiting_reanalysis: 'orange',
	under_analysis: 'blue',
	pending: 'red',
	approved: 'green',
	authorized_by_manager: 'cyan',
	denied: 'purple',
};

const RISK_ANALYSIS_STATE_LABELS = {
	draft: 'Aguardando preenchimento',
	awaiting_analysis: 'Aguardando análise',
	awaiting_reanalysis: 'Aguardando reanálise',
	under_analysis: 'Em análise',
	pending: 'Com pendências',
	approved: 'Concluída',
	authorized_by_manager: 'Autorizado pelo gerente',
	denied: 'Encerrada',
};

const RISK_ANALYSIS_STATE_COLORS = {
	draft: 'blank',
	awaiting_analysis: 'yellow',
	awaiting_reanalysis: 'orange',
	under_analysis: 'blue',
	pending: 'red',
	approved: 'green',
	authorized_by_manager: 'cyan',
	denied: 'purple',
};

const formatters = {
	link: (value, row) => `<a href="${row.url}" class="text-body">${value}</a>`,

	datetime: (value) => {
		if (value) return dayjs(value).format('DD/MM/YYYY [às] hh:mm[h]');

		return null;
	},

	blacklistMemberKind: (value) => {
		return {
			person: 'Pessoa',
			company: 'Empresa',
			vehicle: 'Truck/Carreta',
			phone: 'Número de Telefone',
		}[value];
	},

	role: (value) => {
		return {
			admin: 'Admin',
			junior_analyst: 'Analista 1',
			intermediate_analyst: 'Analista 2',
			senior_analyst: 'Analista 3',
			manager: 'Gerente',
			shipper: 'Embarcador',
		}[value];
	},

	driverAnalysisState: (value) => {
		const color = DRIVER_ANALYSIS_STATE_COLORS[value];
		const label = DRIVER_ANALYSIS_STATE_LABELS[value];
		return `<span class="badge badge-${color}">${label}</span>`;
	},

	riskAnalysisState: (value) => {
		const color = RISK_ANALYSIS_STATE_COLORS[value];
		const label = RISK_ANALYSIS_STATE_LABELS[value];
		return `<span class="badge badge-${color}">${label}</span>`;
	},
};

// adds globally
Object.keys(formatters).forEach((formatterName) => {
	window[`${formatterName}Formatter`] = formatters[formatterName];
});
