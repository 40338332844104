import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	connect() {
		this.intersectionObserver = new IntersectionObserver(this.handleIntersect);
		this.intersectionObserver.observe(this.element);
	}

	disconnect() {
		this.intersectionObserver.unobserve(this.element);
	}

	handleIntersect = (entries) => {
		const lastEntry = entries[entries.length - 1];

		if (lastEntry.isIntersecting) {
			this.element.reload();
		}
	};
}
