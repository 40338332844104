import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const linkActive = this.element.querySelector(".nav-link.active")

    if (linkActive) {
      const tabId = linkActive.getAttribute("href")
      this.enableTab(tabId)
    }
  }

  enableTab = (id) => {
    const tabElement = this.element.querySelector(id)

    if (!tabElement) return

    tabElement.classList.add("show")
    tabElement.classList.add("active")
  }
}
