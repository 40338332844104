import { Controller } from '@hotwired/stimulus';

import IMask from 'imask';
import dayjs from '../src/dayjs';

const DATE_FORMAT = 'DD/MM/YYYY';
const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';

const MASKS_SETTINGS = {
	cellphone: {
		mask: '(00) 00000-0000',
	},
	companyDocument: {
		mask: '00.000.000/0000-00',
	},
	date: {
		mask: Date,
		pattern: DATE_FORMAT,
		lazy: true,
		min: new Date(1900, 0, 1),
		max: new Date(2050, 0, 1),
		format: function (date) {
			return dayjs(date).format(DATE_FORMAT);
		},
		parse: function (str) {
			return dayjs(str, DATE_FORMAT, true);
		},
		blocks: {
			YYYY: {
				mask: IMask.MaskedRange,
				from: 1900,
				to: 2050,
			},
			MM: {
				mask: IMask.MaskedRange,
				from: 1,
				to: 12,
			},
			DD: {
				mask: IMask.MaskedRange,
				from: 1,
				to: 31,
			},
		},
	},
	datetime: {
		mask: Date,
		pattern: DATETIME_FORMAT,
		lazy: false,
		min: new Date(1900, 0, 1),
		max: new Date(2050, 0, 1),
		format: function (datetime) {
			return dayjs(datetime).format(DATETIME_FORMAT);
		},
		parse: function (str) {
			return dayjs(str, DATETIME_FORMAT, true);
		},
		blocks: {
			YYYY: {
				mask: IMask.MaskedRange,
				from: 1900,
				to: 2050,
			},
			MM: {
				mask: IMask.MaskedRange,
				from: 1,
				to: 12,
			},
			DD: {
				mask: IMask.MaskedRange,
				from: 1,
				to: 31,
			},
			HH: {
				mask: IMask.MaskedRange,
				from: 0,
				to: 23,
			},
			mm: {
				mask: IMask.MaskedRange,
				from: 0,
				to: 59,
			},
		},
	},
	currency: {
		mask: Number,
		scale: 2,
		signed: false,
		thousandsSeparator: '.',
		padFractionalZeros: true,
		normalizeZeros: false,
		radix: ',',
		mapToRadix: ['.'],
	},
	personalDocument: {
		mask: '000.000.000-00',
	},
	personalOrCompanyDocument: {
		mask: [
			{
				mask: '000.000.000-00',
			},
			{
				mask: '00.000.000/0000-00',
			},
		],
	},
	phone: {
		mask: [
			{
				mask: '(00) 0000-0000',
			},
			{
				mask: '(00) 00000-0000',
			},
		],
	},
	zipcode: {
		mask: '00000-000',
	},
};

export default class extends Controller {
	static targets = ['input'];

	connect() {
		this.maskInstances = {};
		this.inputTargets.forEach((element) => this.setup(element));
	}

	disconnect() {
		this.inputTargets.forEach((element) => this.destroyMask(element));
	}

	inputName = (element) => element.getAttribute('name');

	getImaskInstance = (inputName) => this.maskInstances[inputName];

	setup = (element) => {
		const inputName = this.inputName(element);
		const maskSettings = MASKS_SETTINGS[element.dataset['maskType']];
		const maskInstance = new IMask(element, maskSettings);
		const triggerValidation = (currentEvent) => {
			if (currentEvent) {
				const fieldValidateEvent = new Event('field:validate');
				currentEvent.target.dispatchEvent(fieldValidateEvent);
			}
		};
		maskInstance.on('complete', triggerValidation);

		this.maskInstances[inputName] = maskInstance;

		//add listener to update mask when change field programatically
		element.addEventListener('field:changed', this.updateMask);
	};

	updateMask = ({ target }) => {
		const inputName = this.inputName(target);
		const imaskInstance = this.getImaskInstance(inputName);

		if (imaskInstance) {
			imaskInstance.updateValue();
		}
	};

	destroyMask = (element) => {
		const inputName = this.inputName(element);
		const imaskInstance = this.getImaskInstance(inputName);
		if (imaskInstance) {
			imaskInstance.destroy();
		}
	};
}
