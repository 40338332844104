import CustomValidator from "./CustomValidator";

export default class CompareFieldsValidator extends CustomValidator {
  constructor(targetField, options) {
    super(targetField, options);

    this._compareWithField = document.querySelector(
      this._options["compareWith"]
    );

    if (this._compareWithField == null) {
      throw new Error("compareWith not found");
    }
    this._compareWithLabel = this._compareWithField.parentElement.querySelector(
      "label"
    ).innerText;
  }

  initialize() {
    this._compareWithField.addEventListener("input", (_event) => {
      this._targetField.dispatchEvent(new Event('input', {
        bubbles: true,
        cancelable: true,
      }));
    });
  }

  hasError() {
    return this._targetField.value != this._compareWithField.value;
  }

  get defaultErrorMessage() {
    return `não é igual ao campo ${this.compareWithLabel}`;
  }
}
