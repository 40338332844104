// regenerate a stimulus controller for bootstrap4 modal

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    open: Boolean,
  };

  connect() {
    if (this.openValue) {
      this.show();
    }
  }

  show = () => {
    $(this.element).modal("show");
  };

  hide = (event) => {
    if (event.detail.success) {
      $(this.element).modal("hide");
    }
  };
}
