import { Controller } from '@hotwired/stimulus';
import Swal from 'sweetalert2';

export default class extends Controller {
	static values = {
		alertsSelector: String,
		modal: String,
	};

	openAlert = async (event) => {
		event.preventDefault();
		event.stopPropagation();

		const alertsElement = document.querySelector(this.alertsSelectorValue);
		const highSeverityAlertsCount = alertsElement.querySelectorAll(
			'.alert-danger, .alert-warning'
		).length;

		if (highSeverityAlertsCount == 0) {
			this.openModal();
			return;
		}
		const options = {
			title: 'Irregularidade(s) no cadastro!',
			text: 'Existem irregularidades na ficha, deseja prosseguir com a liberação?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Sim, continuar!',
			cancelButtonText: 'Não, cancelar.',
		};

		const { isConfirmed } = await Swal.fire(options);

		if (isConfirmed) {
			this.openModal();
		}
	};

	openModal = () => $(this.modalValue).modal('show');
}
