import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';

export default class extends Controller {
	static targets = ['unreadCounter'];

	connect() {
		this.channel = this.subscribeChannel();

		console.debug('connected to sidebar notifications controller');

		if (this.hasNotificationBrowserSupport() && this.shouldRequestPermission()) {
			Notification.requestPermission();
		}
	}

	subscribeChannel() {
		return consumer.subscriptions.create('NotificationsChannel', {
			connected: this._connected.bind(this),
			received: this._received.bind(this),
		});
	}

	_connected() {
		this.requestStatus();
	}

	_received(data) {
		const { event, ...payload } = data;

		switch (event) {
			case 'new_notification':
				this.requestStatus();

				if (this.shouldNotifyViaBrowser()) {
					this.createNativeNotification('Você recebeu uma nova notificação!');
				}
				break;
			case 'status':
				const { total_unread: totalRead } = payload;

				if (totalRead > 0) {
					this.updateUnreadNotificationsCounter(totalRead);
				}
				break;
			default:
				console.log('received message from notifications channel', data);
				break;
		}
	}

	requestStatus = () => this.channel.perform('status');

	updateUnreadNotificationsCounter = (total) => {
		this.unreadCounterTarget.innerHTML = total;

		if (total > 0) {
			this.unreadCounterTarget.parentElement.style.paddingRight = 0;
		}
	};

	hasNotificationBrowserSupport = () => {
		return 'Notification' in window;
	};

	shouldRequestPermission = () => Notification.permission == 'default';

	hasPermissionToNotifyViaBrowser = () => Notification.permission == 'granted';

	isUserInCurrentTab = () => document.visibilityState == 'visible';

	shouldNotifyViaBrowser = () => {
		return (
			this.isUserInCurrentTab() == false &&
			this.hasNotificationBrowserSupport() &&
			this.hasPermissionToNotifyViaBrowser()
		);
	};

	createNativeNotification = (body) => {
		const title = 'SIGAGR';
		const timeout = 5000;
		const settings = {
			body,
			icon: 'https://imgur.com/V6xWrep.png', //SIGA logo
		};

		const notification = new Notification(title, settings);

		notification.onclick = () => {
			window.parent.focus();
			notification.close();
		};

		setTimeout(notification.close(), timeout);
	};
}
