import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
	connect() {
		const dataset = this.element.dataset;

		if (!dataset.remote === true) {
			throw new Error('ajax-form-controller requires data-remote=true');
		}
	}

	submit = (_event) => {
		Rails.fire(this.element, 'submit');
	};
}
