import { trailersPerTruckType } from "."

export default class VehicleForm {
  constructor(form) {
    this.form = form;
    this.trailersWrapper = form.querySelector("#trailers-wrapper");
    this.selector = form.querySelector(
      "#risk_analysis_truck_attributes_vehicle_type"
    );
    this.trailerFieldsTag = form.querySelector("#trailer-template");

    this.initialize();
  }

  initialize() {
    this.selector.addEventListener("change", this.handleSelectChanges);
  }

  handleSelectChanges = (event) => {
    const { value } = event.target;
    const countTrailers = this.getTrailers().length;
    const diff = trailersPerTruckType(value) - countTrailers;

    if (diff > 0) {
      this.arrayOfNumbers(diff).forEach((number) =>
        this.addTrailerFields(countTrailers + number)
      );

      this.form.dispatchEvent(new Event("form:fieldsChanged"));
    } else if (diff < 0) {
      this.deleteTrailers(diff);

      this.form.dispatchEvent(new Event("form:fieldsChanged"));
    }
  };

  getTrailers = () =>
    Array.from(
      document.querySelectorAll(
        "[data-trailer-fields]:not([data-trailer-removed])"
      )
    );

  arrayOfNumbers = (amount) => Array.from({ length: amount }, (_x, i) => i + 1);

  addTrailerFields = (position) => {
    const { id, fields } = this.trailerFieldsTag.dataset;
    const nextId = Math.floor(Math.random() * 1000);
    const formTitle = `Carreta ${position}`;
    const newFields = fields
      .replace(new RegExp(id, "g"), nextId)
      .replace(new RegExp("FIELDS_TITLE"), formTitle)
      .replace(new RegExp("TRAILER_POSITION"), position);

    this.trailerFieldsTag.insertAdjacentHTML("beforebegin", newFields);
  };

  deleteTrailers = (amount) => {
    const elementsToRemove = this.getTrailers().slice(amount).reverse();

    elementsToRemove.forEach((element) => {
      const destroyInput = element.querySelector("input[id$='_destroy']");

      if (destroyInput) {
        destroyInput.value = "true";
        element.setAttribute("data-trailer-removed", "");
        element.style.display = "none";

        // disabling all elements except id and destroy
        element
          .querySelectorAll("input:not([data-field-required-for-delete-action]), select")
          .forEach((element) => element.setAttribute("disabled", ""));
      } else {
        this.trailersWrapper.removeChild(element);
      }
    });
  };
}
