import { Controller } from "@hotwired/stimulus";
import Toast from "../../javascript/src/toast";

export default class extends Controller {
  static targets = [
    "applicationKey",
    "message",
    "deleteButton",
    "form",
    "emailInput",
  ];

  connect() {
    this.formTarget.addEventListener("ajax:success", (event) => {
      const [data, _status, _xhr] = event.detail;
      const { email, raw_password } = data;

      // render email and token
      this.applicationKeyTarget.querySelector(
        "span"
      ).innerHTML = `Email: ${email} - Senha: ${raw_password}`;
      //  hide no application key message
      this.messageTarget.classList.add("d-none");
      // show application key wrapper
      this.applicationKeyTarget.classList.remove("d-none");
      // hide modal
      $("#create-application-key-modal").modal("toggle");
    });

    this.formTarget.addEventListener("ajax:error", (event) => {
      const [data, _status, xhr] = event.detail;

      if (xhr.status == 422) {
        this.emailInputTarget.nextSibling.innerText = data["email"][0];
        this.emailInputTarget.classList.add("is-invalid");
      } else {
        Toast.fire({
          text: "Não foi possível concluir a operação.",
          icon: "error",
        });
      }
    });

    this.deleteButtonTarget.addEventListener("ajax:success", (_data) => {
      this.applicationKeyTarget.classList.add("d-none");
      this.applicationKeyTarget.innerHtml = "";
      this.messageTarget.classList.remove("d-none");

      Toast.fire({ text: "Token removido com sucesso", icon: "success" });
    });
  }
}
